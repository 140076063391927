<div *ngIf="restaurant !== undefined" class="content">
    <div class="row">
        <div class="col">
            <tipeasy-header
                        [title]="restaurant.name"
                        [header]="'Thank you!'"
                        [headerSub]="'Your tip and feedback has been sent & greatly appreciated'"
                        [photo]="employee?.image"
                        [photoCheck]="true"
                        [spacer]="1"
            ></tipeasy-header>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="other-actions">
                <div class="d-grid gap-2 col-10 mx-auto pb-5">
                    <a [routerLink]="['/r', restaurant?.strid, employee?.guid]" class="btn btn-lg btn-other">TIP AGAIN</a>
                    <a [routerLink]="['/r', restaurant?.strid]" class="btn btn-lg btn-other">VIEW OTHER</a>
                </div>
            </div>
        </div>
    </div>
</div>