import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripePaymentRequestButtonElement,
  TokenResult,
} from '@stripe/stripe-js';
import { ApplePaySession } from 'braintree-web';
import { ApplePayPaymentRequest } from 'braintree-web/modules/apple-pay';
import { environment } from '../../environments/environment';
import { WindowRef } from '../classes/window-ref';
import { PaymentResponse } from '../interfaces/api.interface';
import { ModalService } from '../modal/modal.service';
import { ApiService } from '../_services/api.service';
import { SessionService } from '../_services/session.service';
import {
  PaymentDetails,
  PaymentServiceButtons,
  PaymentServiceEvent,
} from './payment.interface';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  events: EventEmitter<PaymentServiceEvent> = new EventEmitter();
  getPaymentDetails!: () => PaymentDetails;
  buttons!: PaymentServiceButtons;
  stripe: Stripe | null = null;

  cardExpiryElement!: StripeCardExpiryElement;
  cardNumberElement!: StripeCardNumberElement;
  cardCvcElement!: StripeCardCvcElement;
  paymentRequestButton!: StripePaymentRequestButtonElement;

  private gPayBaseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  private gPayCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification =
    {
      type: 'CARD',
      parameters: {
        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
        allowedCardNetworks: [
          'AMEX',
          'DISCOVER',
          'INTERAC',
          'JCB',
          'MASTERCARD',
          'VISA',
        ],
      },
    };

  private gPayTokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification =
    {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'stripe',
        'stripe:version': '2020-08-27',
        'stripe:publishableKey': environment?.stripePublishableKey,
      },
    };

  constructor(
    private winRef: WindowRef,
    private modalService: ModalService,
    private apiService: ApiService,
    private zone: NgZone,
    public sessionService: SessionService,
    private router: Router
  ) {}

  async loadStripe() {
    if (this.stripe) {
      return;
    }

    this.stripe = await loadStripe(environment?.stripePublishableKey, {
      apiVersion: '2020-08-27',
    });
  }

  onEvents() {
    return this.events;
  }

  private getGoogleIsReadyToPayRequest(): google.payments.api.IsReadyToPayRequest {
    return {
      ...this.gPayBaseRequest,
      allowedPaymentMethods: [this.gPayCardPaymentMethod],
    };
  }

  async doCardPayment() {
    if (!this.stripe) {
      return;
    }
    this.events.emit({ key: 'paymentInProgress', value: true });
    const result = await this.stripe.createToken(this.cardNumberElement);
    this.processResult(result);
  }

  processResult(result: TokenResult) {
    if (result.token) {
      const paymentDetails = this.getPaymentDetails();

      this.apiService.makePayment(
        {
          gateway: 'stripe',
          source: 'credit_card',
          nonce: result.token.id,
          amount: paymentDetails.amount.toFixed(2),
          currency: paymentDetails.currency,
          employeeGuid: paymentDetails.employeeGuid,
          deviceData: this.sessionService.getDeviceData(),
        },
        (response: PaymentResponse | null) => {
          this.events.emit({
            key: 'paymentInProgress',
            value: false,
          });
          if (response === null) {
            this.modalService.error(
              'Error processing payment information, please try again!'
            );
          } else {
            this.events.emit({
              key: 'paymentInProgress',
              value: false,
            });
            if (response.result === 'OK') {
              this.sessionService.setPaymentId(response?.paymentId ?? '');
              this.sessionService.setPaymentType('Google Pay');
              this.router.navigate([
                '/r',
                paymentDetails.restaurantStrid,
                paymentDetails.employeeGuid,
                'success',
              ]);
              return;
            } else {
              this.modalService.error(response.message);
            }
          }
        }
      );
    } else {
      this.events.emit({ key: 'paymentInProgress', value: false });
    }
  }

  public async initializeCardFields() {
    await this.loadStripe();

    if (!this.stripe) {
      return;
    }

    const elements = this.stripe.elements();

    const style = {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '28px',
        fontWeight: 300,
        fontFamily: 'Montserrat, Roboto, "Helvetica Neue", sans-serif',
        fontSize: '16px',

        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    };

    this.cardNumberElement = elements.create('cardNumber', {
      iconStyle: 'solid',
      showIcon: true,
      style: style,
    });
    this.cardNumberElement.mount('#cc-number');

    this.cardExpiryElement = elements.create('cardExpiry', {
      style: style,
    });
    this.cardExpiryElement.mount('#cc-expiration');

    this.cardCvcElement = elements.create('cardCvc', {
      style: style,
    });
    this.cardCvcElement.mount('#cc-cvv');

    const error = document.querySelector('.error');
    const errorMessage = <HTMLDivElement>error?.querySelector('.message');
    let savedErrors: any[] = [];

    if (error && errorMessage) {
      [
        this.cardNumberElement,
        this.cardExpiryElement,
        this.cardCvcElement,
      ].forEach((element: any, idx) => {
        element.on('change', (event: any) => {
          if (event.error) {
            error?.classList?.add('visible');
            savedErrors[idx] = event.error.message;
            errorMessage.innerText = event.error.message;
          } else {
            savedErrors[idx] = null;

            // Loop over the saved errors and find the first one, if any.
            var nextError = Object.keys(savedErrors)
              .sort()
              .reduce(function (maybeFoundError, key) {
                return maybeFoundError || savedErrors[Number(key)];
              }, null);

            if (nextError) {
              // Now that they've fixed the current error, show another one.
              errorMessage.innerText = nextError;
            } else {
              // The user fixed the last error; no more errors.
              error.classList.remove('visible');
            }
          }
        });
      });
    }
  }

  async initPaymentRequestButton() {
    if (!this.stripe) {
      return;
    }

    const paymentDetails = this.getPaymentDetails();

    const paymentRequest = this.stripe.paymentRequest({
      country: 'LV',
      currency: 'eur',
      total: {
        label: 'TipEasy',
        amount: paymentDetails.amount * 100,
      },
    });

    const elements = this.stripe.elements();

    this.paymentRequestButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
      style: {
        paymentRequestButton: {
          theme: 'dark',
          height: '60px',
        },
      },
    });

    paymentRequest.canMakePayment().then((result) => {
      if (result) {
        this.paymentRequestButton.mount('#payment-request-button');
        const b = <HTMLElement>(
          document.querySelector('#payment-request-button')
        );
        if (b) {
          b.style.display = 'block';
        }
      }
    });
  }

  async init(
    buttons: PaymentServiceButtons,
    getPaymentDetails: () => PaymentDetails
  ) {
    this.buttons = buttons;
    this.getPaymentDetails = getPaymentDetails;

    this.initializePayments();
  }

  async initializePayments() {
    await this.loadStripe();

    //this.initPaymentRequestButton();
    this.initializeApplePayment();
    this.initializeGooglePayment();
    this.initializeCardPayment();
  }

  async initializeApplePayment() {
    const button = this.buttons.aPayButton;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const applePaySession = this.winRef.nativeWindow.ApplePaySession;

    if (
      applePaySession &&
      applePaySession.supportsVersion(3) &&
      applePaySession.canMakePayments()
    ) {
      if (!button) {
        return;
      }
      button.className = '';

      button.addEventListener('click', async (event) => {
        event.preventDefault();

        const paymentDetails = this.getPaymentDetails();
        if ((paymentDetails.amount ?? 0) < 1) {
          this.modalService.error('Tip amount should be 1 EUR or more!');
        } else {
          this.events.emit({ key: 'paymentInProgress', value: true });
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const paymentRequest: ApplePayPaymentRequest = {
          total: {
            amount: paymentDetails.amount.toFixed(2),
            label: 'TipEasy',
          },
          countryCode: 'LV',
          currencyCode: paymentDetails.currency,
          merchantCapabilities: ['supports3DS'],
          supportedNetworks: ['amex', 'masterCard', 'visa', 'maestro'],
        };

        const session: ApplePaySession = new applePaySession(3, paymentRequest);

        session.onvalidatemerchant = (event: any) => {
          this.apiService.validateMerchant(event.validationURL).subscribe(
            (response) => {
              session.completeMerchantValidation(response);
            },
            (validationErr) => {
              console.error('Error validating merchant:', validationErr);
              session.abort();
              this.zone.run(() => {
                this.events.emit({
                  key: 'paymentInProgress',
                  value: false,
                });
                this.modalService.error('Error validating merchant!');
              });
            }
          );
        };

        session.onpaymentauthorized = (event: any) => {
          this.apiService.makePayment(
            {
              gateway: 'stripe',
              source: 'apple_pay_card',
              appleToken: event.payment,
              amount: paymentDetails.amount.toFixed(2),
              currency: paymentDetails.currency,
              employeeGuid: paymentDetails.employeeGuid,
              deviceData: this.sessionService.getDeviceData(),
            },
            (response: PaymentResponse | null) => {
              if (response === null) {
                session.completePayment(1);
                this.modalService.error(
                  'Error processing payment information, please try again!'
                );
              } else {
                if (response.result === 'OK') {
                  session.completePayment(0);
                  this.sessionService.setPaymentId(response?.paymentId ?? '');
                  this.sessionService.setPaymentType('Apple Pay');
                  this.zone.run(() => {
                    this.router.navigate([
                      '/r',
                      paymentDetails.restaurantStrid,
                      paymentDetails.employeeGuid,
                      'success',
                    ]);
                  });
                } else {
                  session.completePayment(1);
                  this.modalService.error(response.message);
                }
              }

              this.events.emit({
                key: 'paymentInProgress',
                value: false,
              });
            }
          );
        };

        session.begin();
      });
    }
  }

  async initializeCardPayment() {
    const button = this.buttons.cardPayButton;

    if (!button) {
      return;
    }

    button.className = '';

    button.addEventListener('click', async (event) => {
      event.preventDefault();

      const paymentData = this.getPaymentDetails();
      if ((paymentData.amount ?? 0) < 1) {
        this.modalService.error('Tip amount should be 1 EUR or more!');
      } else {
        this.sessionService.setTipAmount(paymentData.amount);
        this.router.navigate([
          '/r',
          paymentData.restaurantStrid,
          paymentData.employeeGuid,
          'card',
        ]);
      }
    });
  }

  async initializeGooglePayment() {
    const button = this.buttons.gPayButton;

    if (!button) {
      return;
    }

    const paymentsClient = new google.payments.api.PaymentsClient({
      environment: environment.production ? 'PRODUCTION' : 'TEST',
    });

    paymentsClient
      .isReadyToPay(this.getGoogleIsReadyToPayRequest())
      .then(async (response) => {
        if (response.result) {
          button.className = '';

          button.addEventListener('click', async (event) => {
            event.preventDefault();

            const paymentDetails = this.getPaymentDetails();

            if ((paymentDetails.amount ?? 0) < 1) {
              this.modalService.error('Tip amount should be 1 EUR or more!');
            } else {
              this.events.emit({ key: 'paymentInProgress', value: true });

              const cardPaymentMethod = Object.assign(
                {
                  tokenizationSpecification: this.gPayTokenizationSpecification,
                },
                this.gPayCardPaymentMethod
              );

              const paymentDataRequest: google.payments.api.PaymentDataRequest =
                {
                  ...this.gPayBaseRequest,
                  merchantInfo: {
                    merchantId: environment.googleMerchantId,
                    merchantName: 'TipEasy',
                  },
                  allowedPaymentMethods: [cardPaymentMethod],
                  transactionInfo: {
                    currencyCode: 'EUR',
                    totalPrice: paymentDetails.amount.toFixed(2),
                    totalPriceStatus: 'FINAL',
                  },
                };

              paymentsClient
                .loadPaymentData(paymentDataRequest)
                .then((paymentData) => {
                  const token = JSON.parse(
                    paymentData.paymentMethodData.tokenizationData.token
                  );
                  this.apiService.makePayment(
                    {
                      gateway: 'stripe',
                      source: 'android_pay_card',
                      nonce: token.id,
                      amount: paymentDetails.amount.toFixed(2),
                      currency: paymentDetails.currency,
                      employeeGuid: paymentDetails.employeeGuid,
                      deviceData: this.sessionService.getDeviceData(),
                    },
                    (response: PaymentResponse | null) => {
                      this.events.emit({
                        key: 'paymentInProgress',
                        value: false,
                      });
                      if (response === null) {
                        this.modalService.error(
                          'Error processing payment information, please try again!'
                        );
                      } else {
                        this.events.emit({
                          key: 'paymentInProgress',
                          value: false,
                        });
                        if (response.result === 'OK') {
                          this.sessionService.setPaymentId(
                            response?.paymentId ?? ''
                          );
                          this.sessionService.setPaymentType('Google Pay');
                          this.router.navigate([
                            '/r',
                            paymentDetails.restaurantStrid,
                            paymentDetails.employeeGuid,
                            'success',
                          ]);
                          return;
                        } else {
                          this.modalService.error(response.message);
                        }
                      }
                    }
                  );
                })
                .catch((err) => {
                  this.events.emit({ key: 'paymentInProgress', value: false });
                  const statusCode = err?.statusCode ?? '';

                  switch (statusCode) {
                    case 'CANCELED':
                      this.modalService.info(
                        'Transaction was canceled, please try again...'
                      );
                      break;
                  }
                });
            }
          });
        }
      });
  }
}
