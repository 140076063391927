import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Employee, Restaurant } from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public restaurant: Restaurant | undefined;
  public employee: Employee | undefined;
  public tipAmount: number = 0;
  public braintreeToken: string | undefined;
  public deviceData: string | undefined;
  public customerId: string | undefined;
  public paymentId: string | undefined;
  public paymentType: string | undefined;
  public radioToggle: 'left' | 'right' = 'left';

  constructor(private router: Router) {
    // nothing
  }

  setRestaurant(restaurant: Restaurant) {
    this.restaurant = restaurant;
  }

  getRestaurant() {
    return this.restaurant;
  }

  setEmployee(employee: Employee | undefined) {
    this.employee = employee;
  }

  getEmployee() {
    return this.employee;
  }

  setTipAmount(tipAmount: number) {
    this.tipAmount = tipAmount;
  }

  getTipAmount() {
    return this.tipAmount;
  }

  setBraintreeToken(token: string) {
    this.braintreeToken = token;
  }

  getBraintreeToken() {
    return this.braintreeToken;
  }

  setDeviceData(deviceData: string) {
    this.deviceData = deviceData;
  }

  getDeviceData() {
    return this.deviceData ?? '';
  }

  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  getCustomerId() {
    return this.customerId;
  }

  setPaymentId(id: string) {
    this.paymentId = id;
  }

  getPaymentId() {
    return this.paymentId;
  }

  setPaymentType(id: string) {
    this.paymentType = id;
  }

  getPaymentType() {
    return this.paymentType;
  }

  setRadioToggle(toggle: 'left' | 'right') {
    this.radioToggle = toggle;
  }

  getRadioToggle() {
    return this.radioToggle;
  }
}
