import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientComponent } from './client.component';
import { RouterModule } from '@angular/router';
import { ReviewComponent } from './review/review.component';
import { ElementsModule } from '../elements/elements.module';
import { FormsModule } from '@angular/forms';
import { ThanksComponent } from './thanks/thanks.component';
import { EmployeeComponent } from './employee/employee.component';
import { CardComponent } from './pay/card/card.component';
import { SuccessComponent } from './pay/success/success.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { InfoModule } from '../info/info.module';
import { HeaderComponent } from './header/header.component';

@NgModule({
  declarations: [
    ClientComponent,
    ReviewComponent,
    ThanksComponent,
    EmployeeComponent,
    CardComponent,
    SuccessComponent,
    RestaurantComponent,
    HeaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ElementsModule,
    InfoModule
  ]
})
export class ClientModule { }
