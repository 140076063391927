import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingComponent } from './rating/rating.component';
import { MoneySliderComponent } from './money-slider/money-slider.component';
import { SwiperModule } from 'swiper/angular';
import { RadioSliderComponent } from './radio-slider/radio-slider.component';

@NgModule({
  declarations: [
    RatingComponent,
    MoneySliderComponent,
    RadioSliderComponent
  ],
  imports: [
    CommonModule,
    SwiperModule
  ],
  exports:[
    RatingComponent,
    MoneySliderComponent,
    RadioSliderComponent
  ]
})
export class ElementsModule { }
