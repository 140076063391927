import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WindowRef } from './classes/window-ref';
import { ClientModule } from './client/client.module';
import { HomeComponent } from './home/home.component';
import { ModalModule } from './modal/modal.module';
import { ReadinessComponent } from './readiness/readiness.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ApiService } from './_services/api.service';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RedirectComponent,
    ReadinessComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ModalModule,
    ClientModule,
  ],
  providers: [
    ApiService,
    WindowRef,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
