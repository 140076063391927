import { Component, Input, OnInit } from '@angular/core';
import { InfoService } from '../../info/info.service';

@Component({
  selector: 'tipeasy-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  title: string | undefined;

  @Input()
  backLink: string[] | undefined;

  @Input()
  header: string | undefined;

  @Input()
  headerSub: string | undefined;

  @Input()
  photo: string | undefined;

  @Input()
  photoCheck: boolean = false;

  @Input()
  spacer: number = 0;

  @Input()
  checkMark: boolean = false;

  constructor(private infoService: InfoService) {}

  ngOnInit(): void {}

  spacerSequence(): number[] {
    return [...Array(this.spacer).keys()];
  }

  showInfo() {
    this.infoService.show();
  }
}
