import {
  Component,
  ElementRef,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { InfoService } from './info.service';

@Component({
  selector: 'tipeasy-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoComponent implements OnInit {
  private element: any;

  constructor(private infoService: InfoService, private el: ElementRef) {
    this.element = el.nativeElement;
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }

  show() {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  ngOnInit(): void {
    this.infoService.init(this);
  }
}
