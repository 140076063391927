import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientComponent } from './client/client.component';
import { EmployeeComponent } from './client/employee/employee.component';
import { CardComponent } from './client/pay/card/card.component';
import { SuccessComponent } from './client/pay/success/success.component';
import { RestaurantComponent } from './client/restaurant/restaurant.component';
import { ReviewComponent } from './client/review/review.component';
import { ThanksComponent } from './client/thanks/thanks.component';
import { HomeComponent } from './home/home.component';
import { ReadinessComponent } from './readiness/readiness.component';
import { RedirectComponent } from './redirect/redirect.component';

const routes: Routes = [
  {
    path: 'r',
    component: ClientComponent,
    children: [
      { path: ':name', component: RestaurantComponent },
      { path: ':name/:guid', component: EmployeeComponent },
      { path: ':name/:guid/card', component: CardComponent },
      { path: ':name/:guid/success', component: SuccessComponent },
      { path: ':name/:guid/review', component: ReviewComponent },
      { path: ':name/:guid/thanks', component: ThanksComponent },
      { path: '**', component: RedirectComponent },
    ],
  },
  { path: 'readiness', component: ReadinessComponent },

  { path: '', component: HomeComponent },
  { path: '**', component: RedirectComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
