import { Component } from '@angular/core';
import { Guid } from 'guid-typescript';
import { SessionService } from './_services/session.service';

@Component({
  selector: 'tipeasy-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = "TipEasy";

  constructor(
    private sessionService: SessionService
  ) {
    let customerId: string = localStorage.getItem('customerId') ?? "";
    if(customerId === null) {
      customerId = Guid.create().toString();
      localStorage.setItem('customerId', customerId);
    }

    this.sessionService.setCustomerId(customerId);
  }
}
