<div [hidden]="employee !== null && !paymentInProgress" class="loader"></div>
<div [hidden]="employee === null || paymentInProgress">
    <div class="content" >
        <div class="row">
            <div class="col">
                <div class="content-margin-20">
                    <div class="">
                        <a [routerLink]="['/r', restaurant?.strid, employee?.guid]" class="arrow black">&#x2039;</a>
                    </div>
                    <span class="send-tip">Send tip</span>
                    <div class="content-margin-40">
                        <hr flex/>
                        <div class="payment-types">
                            <img src="https://www.braintreepayments.com/images/visa-logo.svg">
                            <img src="https://www.braintreepayments.com/images/mastercard-logo.svg">
                            <img src="https://www.braintreepayments.com/images/amex-logo.svg">
                            <img src="https://www.braintreepayments.com/images/maestro-logo.svg">
                        </div>

                        <div class="braintree-form">
                            <div class="cardinfo">
                                <label>Payment amount</label>
                                <span class="total">
                                    {{ this.tipAmount }}<small>€</small>
                                </span>
                            </div>

                            <!--<div class="cardinfo">
                                <label for="cc-name">Name on card</label>
                                <div class="form-control" id="cc-name"></div>
                            </div>-->

                            <div class="cardinfo cardinfo-card-number">
                                <label for="cc-number">Card number</label>
                                <div class="form-control" id="cc-number"></div>
                                <div id="card-image"></div>
                            </div>

                            <div class="cardinfo">
                                <label for="cc-expiration">Expiry date</label>
                                <div class="form-control" id="cc-expiration"></div>
                            </div>

                            <div class="cardinfo">
                                <label for="cc-cvv">CVV</label>
                                <div class="form-control" id="cc-cvv"></div>
                            </div>
                        </div>

                        <div class="mt-5 error" role="alert"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                            <path class="base" fill="#000" d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"></path>
                            <path class="glyph" fill="#FFF" d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"></path>
                          </svg>
                          <span class="message">Your card number is incomplete.</span>
                        </div>

                        <div class="payment-buttons payment-buttons-black v-spacing">
                            <a [routerLink]="" (click)="doPayment()" id="card-pay-button">
                                <i class="fas fa-shield-alt"></i>
                                Pay {{ this.tipAmount }}€</a>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
