<div class="jw-modal">
    <div class="jw-modal-body" [ngSwitch]="type">
        <div class="jw-modal_symbol">
            <ng-template ngSwitchCase="success">
                <svg [@symbolAn]="animationState" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.667 526.667">
                    <path d="M213.333 0C95.518 0 0 95.514 0 213.333s95.518 213.333 213.333 213.333c117.828 0 213.333-95.514 213.333-213.333S331.156 0 213.333 0zM174.2 322.918l-93.936-93.93 31.31-31.31 62.625 62.622 140.893-140.898 31.31 31.31-172.204 172.206z" fill="#6AC259"/>
                </svg>
            </ng-template>

            <ng-template ngSwitchCase="error">
                <svg [@symbolAn]="animationState" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 286.667 326.667">
                    <path d="M143.027 0C64.04 0 0 64.04 0 143.027c0 78.996 64.04 143.027 143.027 143.027 78.996 0 143.027-64.022 143.027-143.027C286.054 64.04 222.022 0 143.027 0zm0 259.236c-64.183 0-116.21-52.026-116.21-116.21S78.845 26.82 143.028 26.82s116.21 52.026 116.21 116.21-52.027 116.208-116.21 116.208zm.01-196.51c-10.245 0-17.996 5.346-17.996 13.98v79.202c0 8.644 7.75 13.972 17.996 13.972 9.994 0 17.995-5.55 17.995-13.972v-79.2c0-8.43-8-13.982-17.994-13.982zm0 124.997c-9.843 0-17.853 8.01-17.853 17.86 0 9.833 8.01 17.843 17.852 17.843s17.843-8.01 17.843-17.843c-.002-9.85-8.002-17.86-17.844-17.86z" fill="#E2574C" />
                </svg>
            </ng-template>

            <ng-template ngSwitchCase="info">
                <svg [@symbolAn]="animationState" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 506.667 526.667">
                    <path d="M496.158 248.085C496.158 111.063 385.088.003 248.083.003 111.07.003 0 111.063 0 248.085c0 137 111.07 248.07 248.083 248.07 137.006 0 248.075-111.07 248.075-248.07z" fill="#25B7D3" />
                    <path d="M138.216 173.592c0-13.915 4.467-28.015 13.403-42.297 8.932-14.282 21.972-26.11 39.11-35.486 17.14-9.374 37.134-14.063 59.985-14.063 21.238 0 39.99 3.92 56.25 11.755 16.26 7.838 28.818 18.495 37.683 31.97 8.86 13.48 13.293 28.125 13.293 43.945 0 12.452-2.526 23.367-7.58 32.74-5.054 9.375-11.062 17.468-18.018 24.278-6.96 6.812-19.446 18.275-37.463 34.388-4.982 4.542-8.976 8.535-11.976 11.976-3.004 3.442-5.24 6.59-6.702 9.446-1.466 2.857-2.603 5.713-3.406 8.57-.807 2.855-2.015 7.875-3.625 15.05-2.783 15.237-11.5 22.853-26.146 22.853-7.618 0-14.028-2.49-19.226-7.472-5.2-4.98-7.8-12.377-7.8-22.192 0-12.305 1.902-22.962 5.713-31.97 3.81-9.01 8.862-16.92 15.162-23.73 6.296-6.812 14.794-14.904 25.488-24.28 9.373-8.202 16.15-14.392 20.325-18.567 4.175-4.176 7.69-8.824 10.547-13.954 2.856-5.126 4.285-10.69 4.285-16.7 0-11.717-4.36-21.604-13.074-29.662-8.717-8.054-19.96-12.085-33.728-12.085-16.116 0-27.98 4.064-35.596 12.194-7.62 8.13-14.063 20.105-19.337 35.925-4.98 16.554-14.43 24.828-28.345 24.828-8.206 0-15.127-2.89-20.764-8.68-5.64-5.785-8.458-12.047-8.458-18.786zm107.226 240.82c-8.937 0-16.737-2.895-23.4-8.68-6.668-5.784-10-13.877-10-24.28 0-9.228 3.22-16.99 9.67-23.29 6.443-6.297 14.353-9.448 23.73-9.448 9.228 0 16.99 3.15 23.29 9.448 6.296 6.3 9.448 14.062 9.448 23.29 0 10.256-3.296 18.313-9.888 24.17-6.592 5.86-14.208 8.79-22.85 8.79z" fill="#FFF" />
                </svg>
            </ng-template>
        </div>

        <div class="jw-modal_content">
            {{ content }}
        </div>

        <div class="jw-modal_close" (click)="close()">close</div>
    </div>
</div>
<div class="jw-modal-background"></div>