import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Currency, Employee, Restaurant } from '../../interfaces/api.interface';
import { PaymentDetails } from '../../payment/payment.interface';
import { StripeService } from '../../payment/stripe.service';
import { ApiService } from '../../_services/api.service';
import { SessionService } from '../../_services/session.service';

@Component({
  selector: 'tipeasy-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss'],
})
export class EmployeeComponent implements OnInit {
  public previousUrl: string = '';
  public restaurant: Restaurant | undefined;
  public employee: Employee | undefined;
  public selectedAmount: number = 0;
  public paymentInProgress = false;
  public amountToggleOptions: string[] = ['Choose amount', 'Enter amount'];
  public radioToggle: 'left' | 'right' = 'left';

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public sessionService: SessionService,
    private stripeService: StripeService
  ) {}

  selectAmount(amount: number | string, fromInput = false) {
    const amountNumber = Number(amount);
    if (!fromInput) {
      const inputAmount = <HTMLInputElement>(
        document.getElementById('inputAmount')
      );
      if (inputAmount) {
        inputAmount.value = '';
      }
    }
    this.selectedAmount = amountNumber;
    this.sessionService.setTipAmount(amountNumber);
  }

  setRadioToggle(event: 'left' | 'right') {
    this.radioToggle = event;
    this.sessionService.setRadioToggle(event);
  }

  getAmount() {
    return this.selectedAmount;
  }

  getPaymentDetails(): PaymentDetails {
    return {
      amount: this.getAmount(),
      currency: this.restaurant?.tipCurrency ?? Currency.EUR,
      employeeGuid: this.employee?.guid ?? '',
      restaurantStrid: this.restaurant?.strid ?? '',
    };
  }

  initializeRestaurant(restaurant: Restaurant, guid: string) {
    this.restaurant = restaurant;
    this.employee = restaurant.employees.find((s) => s.guid == guid);
    this.sessionService.setEmployee(this.employee);

    const sessionRestaurant = this.sessionService.getRestaurant();
    if (sessionRestaurant === undefined) {
      this.sessionService.setRestaurant(restaurant);
    }

    setTimeout(() => {
      this.stripeService.init(
        {
          aPayButton: <HTMLButtonElement>(
            document.querySelector('#apple-pay-button')
          ),
          gPayButton: <HTMLButtonElement>(
            document.querySelector('#google-pay-button')
          ),
          cardPayButton: <HTMLButtonElement>(
            document.querySelector('#card-pay-button')
          ),
        },
        this.getPaymentDetails.bind(this)
      );
      this.stripeService.onEvents().subscribe((event) => {
        switch (event.key) {
          case 'paymentInProgress':
            this.paymentInProgress = <boolean>event.value;
            break;
        }
      });
    }, 200);
  }

  ngOnInit(): void {
    const mainWrapper = document.querySelector('.client-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.remove('whitebg');
    }

    this.route.paramMap.subscribe((params) => {
      const guid = params.get('guid') ?? '';
      if (!guid) {
        this.router.navigate(['/']);
      }

      this.selectedAmount = this.sessionService.getTipAmount() || 3;
      this.radioToggle = this.sessionService.getRadioToggle() || 'left';

      const restaurant = this.sessionService.getRestaurant();
      if (restaurant === undefined) {
        this.apiService.loadRestaurant({ employeeGuid: guid }, (restaurant) =>
          this.initializeRestaurant(restaurant, guid)
        );
      } else {
        this.initializeRestaurant(restaurant, guid);
      }
    });
  }
}
