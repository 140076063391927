import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Swiper } from 'swiper';

@Component({
  selector: 'money-slider',
  templateUrl: './money-slider.component.html',
  styleUrls: ['./money-slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MoneySliderComponent implements OnInit {
  swiper: Swiper | undefined;

  @Output()
  onSelectValue: EventEmitter<number> = new EventEmitter();

  @Input()
  initialValue: number = 0;

  constructor() {}

  ngOnInit(): void {}

  sliderSequence(): number[] {
    return [...Array(100).keys()];
  }

  onSwiper(swiper: Swiper) {
    this.swiper = swiper;
  }

  async onSlideChange() {
    while (!this.swiper) {
      await new Promise((r) => setTimeout(r, 100));
    }
    if (this.swiper) {
      this.onSelectValue.emit(this.swiper.activeIndex + 1);
    }
  }
}
