import { Injectable } from '@angular/core';
import { InfoComponent } from './info.component';

@Injectable({
  providedIn: 'root'
})
export class InfoService {
  component: InfoComponent | undefined;

  constructor() { }

  init(c: InfoComponent) {
    this.component = c;
  }

  public show() {
    this.component?.show();
  }
}
