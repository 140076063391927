import { Injectable } from '@angular/core';
import { ModalComponent } from './modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: ModalComponent[] = [];

  add(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }

  open(content?: string) {
    // open modal specified by id
    const modal = this.modals[this.modals.length-1];
    modal.open(content);
  }

  error(content?: string) {
    // open modal specified by id
    const modal = this.modals[this.modals.length-1];
    modal.error(content);
  }

  success(content?: string) {
    // open modal specified by id
    const modal = this.modals[this.modals.length-1];
    modal.success(content);
  }

  info(content?: string) {
    // open modal specified by id
    const modal = this.modals[this.modals.length-1];
    modal.info(content);
  }

  close() {
    // close modal specified by id
    const modal = this.modals[this.modals.length-1];
    modal.close();
  }
}
