import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalService } from './modal.service';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'tipeasy-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('overlayAn', [
      state('void', style({opacity: 0})),
      state('leave', style({opacity: 0})),
      state('enter', style({opacity: 1})),
      transition('void => enter', animate('450ms ease-in-out')),
      transition('enter => leave', animate('450ms ease-in-out'))
    ]),
    trigger('wrapperAn', [
      state('void', style({opacity: 0, transform: 'scale(0.75, 0.75) translate(0, -100vh)'})),
      state('leave', style({opacity: 0, transform: 'scale(0.75, 0.75) translate(0, -100vh)'})),
      state('enter', style({opacity: 1, transform: 'scale(1, 1) translate(0, 0)'})),
      transition('void => enter', animate('450ms cubic-bezier(.5, 1.4, .5, 1)')),
      transition('enter => leave', animate('450ms cubic-bezier(.5, 1.4, .5, 1)'))
    ]),
    trigger('symbolAn', [
      state('void', style({opacity: 0, transform: 'rotate(90deg) scale(0.1, 0.1)'})),
      state('leave', style({opacity: 0, transform: 'rotate(90deg) scale(0.1, 0.1)'})),
      state('enter', style({opacity: 1, transform: 'rotate(0deg)'})),
      transition('void => enter', animate('450ms 100ms ease-in-out')),
      transition('enter => leave', animate('450ms 100ms ease-in-out'))
    ]),
    trigger('messageAn', [
      state('void', style({opacity: 0, transform: 'translate(0, 20px) scale(0.01, 0.01)'})),
      state('leave', style({opacity: 0, transform: 'translate(0, 20px) scale(0.01, 0.01)'})),
      state('enter', style({opacity: 1, transform: 'translate(0, 0)'})),
      transition('void => enter', animate('450ms 100ms ease-in-out')),
      transition('enter => leave', animate('450ms 100ms ease-in-out'))
    ]),
    trigger('shortAn', [
      transition('void => enter', [
        animate('450ms 200ms ease-in-out', keyframes([
          style({opacity: 0, transform: 'scale(0, 0)', offset: 0}),
          style({transform: 'scale(1.5, 1.5)', offset: 0.35}),
          style({transform: 'scale(0.9, 0.9)',  offset: 0.85}),
          style({opacity: 1, transform: 'scale(1, 1)',  offset: 1.0})
        ]))
      ])
    ])
  ]
})
export class ModalComponent implements OnInit, OnDestroy {
  public id: string = "";
  private element: any;
  public content: string = "";
  public animationState = 'enter';
  public type = 'warning';


  constructor(private modalService: ModalService, private el: ElementRef) {
      this.element = el.nativeElement;
  }

  ngOnInit(): void {
      this.id = Guid.create.toString();

      // move element to bottom of page (just before </body>) so it can be displayed above everything else
      document.body.appendChild(this.element);

      // close modal on background click
      this.element.addEventListener('click', (el: any) => {
          if (el?.target?.className === 'jw-modal-background') {
              this.close();
          }
      });

      // add self (this modal instance) to the modal service so it's accessible from controllers
      this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
      this.modalService.remove(this.id);
      this.element.remove();
  }

  error(content?: string): void {
    this.type = 'error';
    this.open(content);
  }

  success(content?: string): void {
    this.type = 'success';
    this.open(content);
  }

  info(content?: string): void {
    this.type = 'info';
    this.open(content);
  }

  // open modal
  open(content?: string): void {
    this.element.style.display = 'block';
    this.content = content ?? '';
    document.body.classList.add('jw-modal-open');
}

  // close modal
  close(): void {
      this.element.style.display = 'none';
      document.body.classList.remove('jw-modal-open');
  }
}
