import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Message } from '../interfaces/api.interface';
import { utils } from '../_helpers/utils';

@Component({
  selector: 'tipeasy-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  hello$ = this.http.get<Message>('/api/hello');
  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.navbarDarkenOnScroll();
    this.navbarTopDropShadow();
  }

  navbarTopDropShadow() {
    var Selector = {
      NAVBAR: '.navbar:not(.navbar-vertical)',
      NAVBAR_VERTICAL: '.navbar-vertical',
      NAVBAR_VERTICAL_CONTENT: '.navbar-vertical-content',
      NAVBAR_VERTICAL_COLLAPSE: 'navbarVerticalCollapse',
    };
    var ClassNames = {
      NAVBAR_GLASS_SHADOW: 'navbar-glass-shadow',
      SHOW: 'show',
    };
    var Events = {
      SCROLL: 'scroll',
      SHOW_BS_COLLAPSE: 'show.bs.collapse',
      HIDDEN_BS_COLLAPSE: 'hidden.bs.collapse',
    };
    var navDropShadowFlag = true;
    var $navbar = document.querySelector(Selector.NAVBAR);
    var $navbarVertical = document.querySelector(Selector.NAVBAR_VERTICAL);
    var $navbarVerticalContent = document.querySelector(
      Selector.NAVBAR_VERTICAL_CONTENT
    );
    var $navbarVerticalCollapse = document.getElementById(
      Selector.NAVBAR_VERTICAL_COLLAPSE
    );
    var html = document.documentElement;
    var breakPoint = utils.getBreakpoint($navbarVertical);

    var setDropShadow = function setDropShadow($elem: any) {
      if ($elem.scrollTop > 0 && navDropShadowFlag) {
        $navbar && $navbar.classList.add(ClassNames.NAVBAR_GLASS_SHADOW);
      } else {
        $navbar && $navbar.classList.remove(ClassNames.NAVBAR_GLASS_SHADOW);
      }
    };

    window.addEventListener(Events.SCROLL, function () {
      setDropShadow(html);
    });

    if ($navbarVerticalContent) {
      $navbarVerticalContent.addEventListener(Events.SCROLL, function () {
        if (window.outerWidth < breakPoint) {
          navDropShadowFlag = true;
          setDropShadow($navbarVerticalContent);
        }
      });
    }

    if ($navbarVerticalCollapse) {
      $navbarVerticalCollapse.addEventListener(
        Events.SHOW_BS_COLLAPSE,
        function () {
          if (window.outerWidth < breakPoint) {
            navDropShadowFlag = false;
            setDropShadow(html);
          }
        }
      );
    }

    if ($navbarVerticalCollapse) {
      $navbarVerticalCollapse.addEventListener(
        Events.HIDDEN_BS_COLLAPSE,
        function () {
          if (
            $navbarVerticalCollapse &&
            utils.hasClass($navbarVerticalCollapse, ClassNames.SHOW) &&
            window.outerWidth < breakPoint
          ) {
            navDropShadowFlag = false;
          } else {
            navDropShadowFlag = true;
          }

          setDropShadow(html);
        }
      );
    }
  }

  navbarDarkenOnScroll() {
    var Selector = {
      NAVBAR: '[data-navbar-darken-on-scroll]',
      NAVBAR_COLLAPSE: '.navbar-collapse',
      NAVBAR_TOGGLER: '.navbar-toggler',
    };
    var ClassNames = {
      COLLAPSED: 'collapsed',
    };
    var Events = {
      SCROLL: 'scroll',
      SHOW_BS_COLLAPSE: 'show.bs.collapse',
      HIDE_BS_COLLAPSE: 'hide.bs.collapse',
      HIDDEN_BS_COLLAPSE: 'hidden.bs.collapse',
    };
    var DataKey = {
      NAVBAR_DARKEN_ON_SCROLL: 'navbar-darken-on-scroll',
    };
    var navbar = <HTMLElement>document.querySelector(Selector.NAVBAR);

    if (navbar) {
      var parent = document.documentElement;
      var windowHeight = window.innerHeight;
      var html = document.documentElement;
      var navbarCollapse = <HTMLElement>(
        navbar.querySelector(Selector.NAVBAR_COLLAPSE)
      );
      var allColors = utils._objectSpread(
        utils._objectSpread({}, utils.getColors(parent)),
        utils.getGrays(parent)
      );

      var name = utils.getData(navbar, DataKey.NAVBAR_DARKEN_ON_SCROLL);
      var bgClassName = 'bg-1100';

      var _window$getComputedSt = window.getComputedStyle(navbar),
        backgroundImage = _window$getComputedSt.backgroundImage;

      var transition = 'background-color 0.35s ease';
      navbar.style.backgroundImage = 'none'; // Change navbar background color on scroll

      window.addEventListener(Events.SCROLL, function () {
        var scrollTop = html.scrollTop;
        var alpha = (scrollTop / windowHeight) * 2;
        alpha >= 0.85 && (alpha = 0.85);
        navbar.style.backgroundColor = 'rgba(11,23,39,'.concat(
          alpha.toString(),
          ')'
        );
        navbar.style.backgroundImage = alpha > 0 ? backgroundImage : 'none';
      }); // Toggle bg class on window resize

      utils.resize(function () {
        var breakPoint = utils.getBreakpoint(navbar);

        const navbarToggler = <HTMLElement>(
          navbar.querySelector(Selector.NAVBAR_TOGGLER)
        );

        if (window.innerWidth > breakPoint) {
          navbar.classList.remove(bgClassName);
          navbar.style.backgroundImage = html.scrollTop
            ? backgroundImage
            : 'none';
          navbar.style.transition = 'none';
        } else if (
          navbarToggler &&
          !utils.hasClass(navbarToggler, ClassNames.COLLAPSED)
        ) {
          navbar.classList.add(bgClassName);
          navbar.style.backgroundImage = backgroundImage;
        }

        if (window.innerWidth <= breakPoint) {
          navbar.style.transition = utils.hasClass(navbarCollapse, 'show')
            ? transition
            : 'none';
        }
      });
      navbarCollapse.addEventListener(Events.SHOW_BS_COLLAPSE, function () {
        navbar.classList.add(bgClassName);
        navbar.style.backgroundImage = backgroundImage;
        navbar.style.transition = transition;
      });
      navbarCollapse.addEventListener(Events.HIDE_BS_COLLAPSE, function () {
        navbar.classList.remove(bgClassName);
        !html.scrollTop && (navbar.style.backgroundImage = 'none');
      });
      navbarCollapse.addEventListener(Events.HIDDEN_BS_COLLAPSE, function () {
        navbar.style.transition = 'none';
      });
    }
  }
}
