import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Currency,
  Employee,
  Restaurant,
} from '../../../interfaces/api.interface';
import { ModalService } from '../../../modal/modal.service';
import { BraintreeService } from '../../../payment/braintree.service';
import { StripeService } from '../../../payment/stripe.service';
import { ApiService } from '../../../_services/api.service';
import { SessionService } from '../../../_services/session.service';

@Component({
  selector: 'tipeasy-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  public employee: Employee | undefined;
  public restaurant: Restaurant | undefined;
  public tipAmount: string = '';
  public braintreeToken: string | undefined;

  public paymentInProgress = false;

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private modalService: ModalService,
    private apiService: ApiService,
    private braintreeService: BraintreeService,
    private stripeService: StripeService
  ) {}

  doPayment() {
    this.stripeService.doCardPayment();
  }

  ngOnInit(): void {
    this.employee = this.sessionService.getEmployee();
    this.restaurant = this.sessionService.getRestaurant();
    const tipAmount = this.sessionService.getTipAmount();
    //this.braintreeToken = this.sessionService.getBraintreeToken();

    if (this.employee === undefined || tipAmount === undefined) {
      this.router.navigate(['/']);

      return;
    }

    const mainWrapper = document.querySelector('.client-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.add('whitebg');
    }

    this.tipAmount = tipAmount.toFixed(2);

    setTimeout(() => {
      this.stripeService.init(
        {
          aPayButton: <HTMLButtonElement>(
            document.querySelector('#apple-pay-button')
          ),
          gPayButton: <HTMLButtonElement>(
            document.querySelector('#google-pay-button')
          ),
          cardPayButton: <HTMLButtonElement>(
            document.querySelector('#card-pay-button')
          ),
        },
        () => ({
          amount: tipAmount,
          currency: Currency.EUR,
          employeeGuid: this.employee?.guid ?? '',
          restaurantStrid: this.restaurant?.strid ?? '',
        })
      );

      this.stripeService.onEvents().subscribe((event) => {
        switch (event.key) {
          case 'paymentInProgress':
            this.paymentInProgress = <boolean>event.value;
            break;
        }
      });
    }, 200);

    this.stripeService.initializeCardFields();

    //this.braintreeService.initializeHostedFields();
  }
}
