import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import * as braintree from 'braintree-web';
import { ApplePayPaymentRequest } from 'braintree-web/modules/apple-pay';
import { environment } from '../../environments/environment';
import { WindowRef } from '../classes/window-ref';
import { PaymentResponse } from '../interfaces/api.interface';
import { ModalService } from '../modal/modal.service';
import { ApiService } from '../_services/api.service';
import { SessionService } from '../_services/session.service';
import {
  PaymentDetails,
  PaymentServiceButtons,
  PaymentServiceEvent,
} from './payment.interface';

@Injectable({
  providedIn: 'root',
})
export class BraintreeService {
  events: EventEmitter<PaymentServiceEvent> = new EventEmitter();
  getPaymentDetails!: () => PaymentDetails;
  clientInstance!: braintree.Client;
  buttons!: PaymentServiceButtons;
  public hostedFieldsInstance: braintree.HostedFields | undefined;

  constructor(
    private winRef: WindowRef,
    private modalService: ModalService,
    private apiService: ApiService,
    private zone: NgZone,
    public sessionService: SessionService,
    private router: Router
  ) {}

  onEvents() {
    return this.events;
  }

  async init(
    buttons: PaymentServiceButtons,
    getPaymentDetails: () => PaymentDetails
  ) {
    this.buttons = buttons;
    this.getPaymentDetails = getPaymentDetails;

    const token = this.sessionService.getBraintreeToken();

    if (token === undefined) {
      this.apiService.loadClientToken().subscribe(async (data) => {
        this.sessionService.setBraintreeToken(data.token);
        this.initializePayments(data.token);
      });
    } else {
      this.initializePayments(token);
    }
  }

  async initializePayments(token: string) {
    const clientInstance = await braintree.client.create({
      authorization: token,
    });

    braintree.dataCollector
      .create({
        client: clientInstance,
      })
      .then((dataCollectorInstance) => {
        this.sessionService.setDeviceData(dataCollectorInstance.deviceData);
      })
      .catch((err) => {
        this.sessionService.setDeviceData('');
      });

    this.initializeCardPayment();
    this.initializeGooglePayment(clientInstance);
    this.initializeApplePayment(clientInstance);
  }

  async initializeApplePayment(clientInstance: braintree.Client) {
    const button = this.buttons.aPayButton;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const applePaySession = this.winRef.nativeWindow.ApplePaySession;

    if (
      applePaySession &&
      applePaySession.supportsVersion(3) &&
      applePaySession.canMakePayments()
    ) {
      if (!button) {
        return;
      }

      braintree.applePay
        .create({
          client: clientInstance,
        })
        .then((applePaymentInstance) => {
          button.className = '';
          /*ApplePaySession.canMakePaymentsWithActiveCard(applePaymentInstance.merchantIdentifier)
          .then((canMakePaymentsWithActiveCard) => {
            if(canMakePaymentsWithActiveCard) {*/
          button.addEventListener('click', async (event) => {
            event.preventDefault();

            const paymentDetails = this.getPaymentDetails();
            if ((paymentDetails.amount ?? 0) < 1) {
              this.modalService.error('Tip amount should be 1 EUR or more!');
            } else {
              this.events.emit({ key: 'paymentInProgress', value: true });

              const paymentRequest: ApplePayPaymentRequest =
                await applePaymentInstance.createPaymentRequest({
                  total: {
                    label: 'TipEasy',
                    amount: paymentDetails.amount.toFixed(2),
                  },
                });

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const session: braintree.ApplePaySession = new ApplePaySession(
                3,
                paymentRequest
              );

              session.onvalidatemerchant = (event) => {
                applePaymentInstance
                  .performValidation({
                    validationURL: event.validationURL,
                    displayName: 'TipEasy',
                  })
                  .then((merchantSession: any) => {
                    session.completeMerchantValidation(merchantSession);
                  })
                  .catch((validationErr: any) => {
                    // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                    console.error('Error validating merchant:', validationErr);
                    session.abort();
                    this.zone.run(() => {
                      this.events.emit({
                        key: 'paymentInProgress',
                        value: false,
                      });
                      this.modalService.error('Error validating merchant!');
                    });
                  });
              };

              session.onpaymentauthorized = (event) => {
                console.log(
                  'Your shipping address is:',
                  event.payment.shippingContact
                );

                applePaymentInstance
                  .tokenize({
                    token: event.payment.token,
                  })
                  .then((payload: { nonce: any }) => {
                    this.apiService.makePayment(
                      {
                        gateway: 'braintree',
                        source: 'apple_pay_card',
                        nonce: payload.nonce,
                        amount: paymentDetails.amount.toFixed(2),
                        currency: paymentDetails.currency,
                        employeeGuid: paymentDetails.employeeGuid,
                        deviceData: this.sessionService.getDeviceData(),
                      },
                      (response: PaymentResponse | null) => {
                        this.zone.run(() =>
                          this.events.emit({
                            key: 'paymentInProgress',
                            value: false,
                          })
                        );
                        if (response === null) {
                          this.zone.run(() => {
                            this.events.emit({
                              key: 'paymentInProgress',
                              value: false,
                            });
                            this.modalService.error(
                              'Error processing payment, please try again!'
                            );
                          });
                        } else {
                          if (response.result === 'OK') {
                            session.completePayment(1);
                            this.sessionService.setPaymentId(
                              response?.paymentId ?? ''
                            );
                            this.sessionService.setPaymentType('Apple Pay');
                            setTimeout(
                              () =>
                                this.zone.run(() =>
                                  this.router.navigate([
                                    '/r',
                                    paymentDetails.restaurantStrid,
                                    paymentDetails.employeeGuid,
                                    'success',
                                  ])
                                ),
                              200
                            );
                            return;
                          } else {
                            this.zone.run(() => {
                              this.events.emit({
                                key: 'paymentInProgress',
                                value: false,
                              });
                              this.modalService.error(
                                'Error processing payment, please try again!'
                              );
                            });
                          }
                        }
                      }
                    );
                  })
                  .catch((tokenizeErr: any) => {
                    console.error('Error tokenizing Apple Pay:', tokenizeErr);
                    session.completePayment(2);
                    this.zone.run(() => {
                      this.events.emit({
                        key: 'paymentInProgress',
                        value: false,
                      });
                      this.modalService.error(
                        'Error processing payment, please try again!'
                      );
                    });
                  });
              };

              //session.canMakePaymentsWithActiveCard()

              session.begin();
            }
          }); // button
        });
    }
  }

  async initializeCardPayment() {
    const button = this.buttons.cardPayButton;

    if (!button) {
      return;
    }

    button.className = '';

    button.addEventListener('click', async (event) => {
      event.preventDefault();

      const paymentData = this.getPaymentDetails();
      if ((paymentData.amount ?? 0) < 1) {
        this.modalService.error('Tip amount should be 1 EUR or more!');
      } else {
        this.sessionService.setTipAmount(paymentData.amount);
        this.router.navigate([
          '/r',
          paymentData.restaurantStrid,
          paymentData.employeeGuid,
          'card',
        ]);
      }
    });
  }

  async initializeGooglePayment(clientInstance: braintree.Client) {
    const button = this.buttons.gPayButton;

    if (!button) {
      return;
    }

    const paymentsClient = new google.payments.api.PaymentsClient({
      environment: environment.production ? 'PRODUCTION' : 'TEST',
    });

    const googlePaymentInstance = await braintree.googlePayment.create({
      client: clientInstance,
      googlePayVersion: 2,
      googleMerchantId: environment.googleMerchantId,
    });

    paymentsClient
      .isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: (
          await googlePaymentInstance.createPaymentDataRequest()
        ).allowedPaymentMethods,
        existingPaymentMethodRequired: true,
      })
      .then(async (response) => {
        if (response.result) {
          button.className = '';

          button.addEventListener('click', async (event) => {
            event.preventDefault();
            const paymentDetails = this.getPaymentDetails();

            if ((paymentDetails.amount ?? 0) < 1) {
              this.modalService.error('Tip amount should be 1 EUR or more!');
            } else {
              this.events.emit({ key: 'paymentInProgress', value: true });

              const paymentDataRequest =
                await googlePaymentInstance.createPaymentDataRequest({
                  transactionInfo: {
                    currencyCode: environment.braintreeCurrency,
                    totalPriceStatus: 'FINAL',
                    totalPrice: paymentDetails.amount.toFixed(2), // Your amount
                  },
                });

              const cardPaymentMethod =
                paymentDataRequest.allowedPaymentMethods[0];
              cardPaymentMethod.parameters.billingAddressRequired = false;

              paymentsClient
                .loadPaymentData(paymentDataRequest)
                .then((paymentData) => {
                  googlePaymentInstance.parseResponse(
                    paymentData,
                    (err, result) => {
                      if (err) {
                        this.modalService.error(
                          'Error processing payment information!'
                        );
                        this.events.emit({
                          key: 'paymentInProgress',
                          value: false,
                        });
                      } else {
                        this.apiService.makePayment(
                          {
                            gateway: 'braintree',
                            source: 'android_pay_card',
                            nonce: result.nonce,
                            amount: paymentDetails.amount.toFixed(2),
                            currency: paymentDetails.currency,
                            employeeGuid: paymentDetails.employeeGuid,
                            deviceData: this.sessionService.getDeviceData(),
                          },
                          (response: PaymentResponse | null) => {
                            if (response === null) {
                              this.modalService.error(
                                'Error processing payment information, please try again!'
                              );
                            } else {
                              this.events.emit({
                                key: 'paymentInProgress',
                                value: false,
                              });
                              if (response.result === 'OK') {
                                this.sessionService.setPaymentId(
                                  response?.paymentId ?? ''
                                );
                                this.sessionService.setPaymentType(
                                  'Google Pay'
                                );
                                this.router.navigate([
                                  '/r',
                                  paymentDetails.restaurantStrid,
                                  paymentDetails.employeeGuid,
                                  'success',
                                ]);
                                return;
                              } else {
                                this.modalService.error(response.message);
                              }
                            }
                          }
                        );
                      }
                    }
                  );
                })
                .catch((err) => {
                  this.events.emit({ key: 'paymentInProgress', value: false });
                  const statusCode = err?.statusCode ?? '';

                  switch (statusCode) {
                    case 'CANCELED':
                      this.modalService.info(
                        'Transaction was canceled, please try again...'
                      );
                      break;
                  }
                });
            }
          }); // button
        }
      });
  }

  async initializeHostedFields() {
    const token = this.sessionService.getBraintreeToken();

    if (!token) {
      this.router.navigate(['/']);
      return;
    }

    const clientInstance = await braintree.client.create({
      authorization: token,
    });

    braintree.hostedFields.create(
      {
        client: clientInstance,
        styles: {
          input: {
            // change input styles to match
            // bootstrap styles
            'font-size': '1rem',
            color: '#495057',
          },
        },
        fields: {
          cardholderName: {
            selector: '#cc-name',
            placeholder: 'Name as it appears on your card',
          },
          number: {
            selector: '#cc-number',
            placeholder: '4111 1111 1111 1111',
          },
          cvv: {
            selector: '#cc-cvv',
            placeholder: '123',
          },
          expirationDate: {
            selector: '#cc-expiration',
            placeholder: 'MM / YY',
          },
        },
      },
      (err, hostedFieldsInstance: braintree.HostedFields) => {
        if (err) {
          console.error(err);
          return;
        }

        this.hostedFieldsInstance = hostedFieldsInstance;

        function checkHostFields(event: any) {
          const state = hostedFieldsInstance.getState();
          const formValid = Object.keys(state.fields).reduce<boolean>(
            (result: boolean, key: string) => {
              if (!state.fields[key].isEmpty) {
                if (state.fields[key].isValid) {
                  state.fields[key].container.classList.remove('is-invalid');
                } else {
                  state.fields[key].container.classList.add('is-invalid');
                }
              }
              return (
                result &&
                state.fields[key].isValid &&
                !state.fields[key].isEmpty
              );
            },
            true
          );

          const payButton = document.querySelector('#card-pay-button');
          if (payButton) {
            if (formValid) {
              payButton.className = formValid ? '' : 'disabled';
            }
          }
        }

        hostedFieldsInstance.on('blur', (event) => checkHostFields(event));
        hostedFieldsInstance.on('validityChange', (event) =>
          checkHostFields(event)
        );

        hostedFieldsInstance.on('empty', function (event) {
          const cardImage = document.querySelector('#card-image');
          if (cardImage) {
            cardImage.className = '';
          }
        });

        hostedFieldsInstance.on('cardTypeChange', function (event) {
          const cardImage = document.querySelector('#card-image');
          if (!cardImage) {
            return;
          }
          // Change card bg depending on card type
          if (event.cards.length === 1) {
            cardImage.className = event.cards[0].type;

            // Change the CVV length for AmericanExpress cards
            if (event.cards[0].code.size === 4) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore fucking package maintainers forgot to add this shit to @types
              hostedFieldsInstance.setAttribute({
                field: 'cvv',
                attribute: 'placeholder',
                value: '1234',
              });
            }
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore fucking package maintainers forgot to add this shit to @types
            hostedFieldsInstance.setAttribute({
              field: 'cvv',
              attribute: 'placeholder',
              value: '123',
            });
          }
        });
      }
    );
  }

  public async doCardPayment() {
    if (this.hostedFieldsInstance !== undefined) {
      const paymentData = this.getPaymentDetails();

      this.hostedFieldsInstance.tokenize((err: any, payload: any) => {
        this.events.emit({
          key: 'paymentInProgress',
          value: true,
        });

        if (err) {
          this.events.emit({
            key: 'paymentInProgress',
            value: false,
          });

          switch (err.code) {
            case 'HOSTED_FIELDS_FIELDS_INVALID':
              this.modalService.error('Some fields are invalid!');
              err.details.invalidFields.forEach(function (fieldContainer: any) {
                fieldContainer.classList.add('is-invalid');
              });
              break;

            case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
              this.modalService.error('CVV did not pass verification!');
              const cvv = document.querySelector('#cc-cvv');
              if (cvv) {
                cvv.classList.add('is-invalid');
              }
              break;

            default:
              this.modalService.error(
                'Error processing payment information, please try again!'
              );
              break;
          }
          return;
        }

        this.apiService.makePayment(
          {
            gateway: 'braintree',
            source: 'credit_card',
            nonce: payload.nonce,
            amount: paymentData.amount.toFixed(2),
            currency: paymentData.currency,
            employeeGuid: paymentData.employeeGuid,
            deviceData: this.sessionService.getDeviceData(),
          },
          (response: PaymentResponse | null) => {
            if (response === null) {
              this.modalService.error(
                'Error processing payment information, please try again!'
              );
            } else {
              this.events.emit({
                key: 'paymentInProgress',
                value: false,
              });
              if (response.result === 'OK') {
                this.sessionService.setPaymentId(response?.paymentId ?? '');
                this.sessionService.setPaymentType('Card Payment');
                this.router.navigate([
                  '/r',
                  paymentData.restaurantStrid,
                  paymentData.employeeGuid,
                  'success',
                ]);
                return;
              } else {
                this.modalService.error(response.message);
              }
            }
          }
        );
      });
    }
  }
}
