<div styele="width: 200px">
<fieldset class="rating">
    <ng-container *ngFor='let x of numSequence()'>
        <input type="radio" id="star{{ x }}" name="rating" value="{{ x }}" (click)="inputChanged($event)"/>
        <label class="full" for="star{{ x }}" title="{{ x }} stars">
            <span class="fa-stack fa-size">
                <i class="far fa-circle fa-stack-2x"></i>
                <i class="fas fa-star fa-stack-1x"></i>
            </span>
        </label>
    </ng-container>
</fieldset>

</div>