export namespace utils {
  export function getColor(...args: any) {
    const dom =
      args.length > 1 && args[1] !== undefined
        ? args[1]
        : document.documentElement;
    return getComputedStyle(dom)
      .getPropertyValue('--falcon-'.concat(args[0]))
      .trim();
  }

  export function getColors(dom?: any) {
    return {
      primary: getColor('primary', dom),
      secondary: getColor('secondary', dom),
      success: getColor('success', dom),
      info: getColor('info', dom),
      warning: getColor('warning', dom),
      danger: getColor('danger', dom),
      light: getColor('light', dom),
      dark: getColor('dark', dom),
    };
  }

  export function hexToRgb(hexValue: string) {
    let hex;
    hexValue.indexOf('#') === 0
      ? (hex = hexValue.substring(1))
      : (hex = hexValue); // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")

    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      })
    );
    const r = result
      ? [
          parseInt(result[1], 16),
          parseInt(result[2], 16),
          parseInt(result[3], 16),
        ]
      : null;
    return r?.toString() ?? '';
  }

  export function rgbaColor(...args: any[]) {
    const color: string =
      args.length > 0 && args[0] !== undefined ? args[0] : '#fff';
    var alpha = args.length > 1 && args[1] !== undefined ? args[1] : 0.5;
    return 'rgba('.concat(hexToRgb(color), ', ').concat(alpha, ')');
  }

  export function camelize(str: string) {
    const text = str.replace(/[-_\s.]+(.)?/g, function (_, c) {
      return c ? c.toUpperCase() : '';
    });
    return ''.concat(text.substr(0, 1).toLowerCase()).concat(text.substr(1));
  }

  export function getData(el: any, data: string) {
    try {
      return JSON.parse(el.dataset[camelize(data)]);
    } catch (e) {
      return el.dataset[camelize(data)];
    }
  }

  export function ownKeys(object: any, enumerableOnly = false) {
    const keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
      let symbols = Object.getOwnPropertySymbols(object).map((sym) =>
        sym.toString()
      );
      if (enumerableOnly)
        symbols = symbols
          .filter((sym) => {
            return Object.getOwnPropertyDescriptor(object, sym)?.enumerable;
          })
          .map((sym) => sym.toString());
      keys.push.apply(keys, symbols);
    }
    return keys;
  }

  export function defineProperty(obj: any, key: string, value: any) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true,
      });
    } else {
      obj[key] = value;
    }
    return obj;
  }

  export function objectSpread(...args: any[]) {
    for (var i = 1; i < args.length; i++) {
      var source = args[i] != null ? args[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          defineProperty(args[0], key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(
          args[0],
          Object.getOwnPropertyDescriptors(source)
        );
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(
            args[0],
            key,
            Object.getOwnPropertyDescriptor(source, key)?.value
          );
        });
      }
    }
    return args[0];
  }

  export function getGrays(dom?: any) {
    return {
      white: getColor('white', dom),
      100: getColor('100', dom),
      200: getColor('200', dom),
      300: getColor('300', dom),
      400: getColor('400', dom),
      500: getColor('500', dom),
      600: getColor('600', dom),
      700: getColor('700', dom),
      800: getColor('800', dom),
      900: getColor('900', dom),
      1000: getColor('1000', dom),
      1100: getColor('1100', dom),
      black: getColor('black', dom),
    };
  }

  export function getPosition(
    pos: number[],
    params: any,
    dom: any,
    rect: any,
    size: any
  ) {
    return {
      top: pos[1] - size.contentSize[1] - 10,
      left: pos[0] - size.contentSize[0] / 2,
    };
  }

  export function resize(fn: (this: Window, ev: UIEvent) => any) {
    return window.addEventListener('resize', fn);
  }

  export function getBreakpoint(el: any) {
    var breakpoints: any = {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1540,
    };

    var classes = el && el.classList.value;
    var breakpoint;

    if (classes) {
      breakpoint =
        breakpoints[
          classes
            .split(' ')
            .filter(function (cls: any) {
              return cls.includes('navbar-expand-');
            })
            .pop()
            .split('-')
            .pop()
        ];
    }

    return breakpoint;
  }

  export function addClass(el: HTMLElement, className: string) {
    el.classList.add(className);
  }

  export function hasClass(el: HTMLElement, className: string) {
    !el && false;
    return el.classList.value.includes(className);
  }

  export function _objectSpread(...args: any) {
    for (var i = 1; i < args.length; i++) {
      var source = args[i] != null ? args[i] : {};
      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          _defineProperty(args, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(args, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key: PropertyKey) {
          const d = Object.getOwnPropertyDescriptor(source, key);
          if (d) {
            Object.defineProperty(args, key, d);
          }
        });
      }
    }
    return args;
  }

  export function _defineProperty(obj: any, key: any, value: any) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true,
      });
    } else {
      obj[key] = value;
    }
    return obj;
  }
}
