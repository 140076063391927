import { Component, ViewEncapsulation } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { slideInAnimation } from '../route-transition-animations';

@Component({
  selector: 'tipeasy-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  animations: [slideInAnimation],
  encapsulation: ViewEncapsulation.None
})
export class ClientComponent {

  constructor(
    private router: Router,
  ) { }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.index;
  }

}
