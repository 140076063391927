<div class="row">
    <div class="col">
        <div class="content">
            <div class="svg-wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.667 526.667">
                    <path d="M213.333 0C95.518 0 0 95.514 0 213.333s95.518 213.333 213.333 213.333c117.828 0 213.333-95.514 213.333-213.333S331.156 0 213.333 0zM174.2 322.918l-93.936-93.93 31.31-31.31 62.625 62.622 140.893-140.898 31.31 31.31-172.204 172.206z" fill="#6AC259"/>
                </svg>
            </div>

            <p>
                Your payment is<br>complete
            </p>

            <div class="payment-buttons payment-buttons-black v-spacing">
                <a [routerLink]="['/r', restaurant?.strid, employee?.guid, 'review']">
                    <i class="fas fa-check"></i>
                    OK</a>
            </div>
        </div>
    </div>
</div>