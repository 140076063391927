import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restaurant, TipSent } from '../../interfaces/api.interface';
import { ApiService } from '../../_services/api.service';
import { SessionService } from '../../_services/session.service';

@Component({
  selector: 'tipeasy-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss'],
})
export class RestaurantComponent implements OnInit {
  restaurant: Restaurant | null = null;
  tipsSent: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public sessionService: SessionService
  ) {}

  isToday(someDate: Date) {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  ngOnInit(): void {
    const mainWrapper = document.querySelector('.client-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.remove('whitebg');
    }

    try {
      let tipsSentObj: TipSent[] = JSON.parse(
        localStorage.getItem('tipsSentObj') ?? ''
      );
      tipsSentObj = tipsSentObj.filter((n) => this.isToday(n.ts));
      this.tipsSent = tipsSentObj.map((n) => n.guid);
    } catch (e) {}

    this.route.paramMap.subscribe((params) => {
      const name = params.get('name');

      if (name === null) {
        this.router.navigate(['/']);
        return;
      }

      this.apiService.loadRestaurant({ strid: name }, (restaurant) => {
        this.restaurant = restaurant;
        this.sessionService.setRestaurant(restaurant);
      });
    });
  }
}
