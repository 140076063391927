import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Employee,
  Restaurant,
  ReviewRequest,
} from '../../interfaces/api.interface';
import { ApiService } from '../../_services/api.service';
import { SessionService } from '../../_services/session.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  public restaurant: Restaurant | undefined;
  public employee: Employee | undefined;
  public tipAmount: number = 0;
  public paymentType: string | undefined;
  public paymentId: string | undefined;
  public selectedRating = 0;
  public reviewContent = '';

  constructor(
    private apiService: ApiService,
    private router: Router,
    public sessionService: SessionService,
    private route: ActivatedRoute
  ) {}

  onSelectedRating(e: number) {
    this.selectedRating = Number(e);
  }

  submitReview() {
    const request: ReviewRequest = {
      review: this.reviewContent,
      stars: this.selectedRating,
      paymentId: this.paymentId ?? '',
    };
    this.apiService.submitReview(request).subscribe(
      (response) => {
        this.router.navigate([
          '/r',
          this.restaurant?.strid,
          this.employee?.guid,
          'thanks',
        ]);
        return;
      },
      (err) => {
        this.router.navigate([
          '/r',
          this.restaurant?.strid,
          this.employee?.guid,
          'thanks',
        ]);
      }
    );
  }

  ngOnInit(): void {
    const mainWrapper = document.querySelector('.client-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.remove('whitebg');
    }

    this.restaurant = this.sessionService.getRestaurant();
    this.employee = this.sessionService.getEmployee();
    this.paymentId = this.sessionService.getPaymentId();

    if (!this.restaurant || !this.employee || !this.paymentId) {
      this.router.navigate(['/']);
      return;
    }

    this.tipAmount = this.sessionService.getTipAmount();
    this.paymentType = this.sessionService.getPaymentType() ?? '';
  }
}
