import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  @Input()
  stars = 5;

  @Output()
  selectedRating: EventEmitter<number> = new EventEmitter();

  constructor() { }

  numSequence(): number[] {
    return  [...Array(this.stars).keys()].map(i => i + 1).reverse();
  }

  inputChanged(e: any) {
    this.selectedRating.emit(e.target?.value);
  }

  ngOnInit(): void {
  }

}
