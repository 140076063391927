<div *ngIf="restaurant === null" class="loader"></div>
<div *ngIf="restaurant !== null">
    <div class="content">
        <div class="row">
            <div class="col">
                <tipeasy-header
                    [header]="'Choose who to tip'"
                ></tipeasy-header>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="employee-list">
                    <img src="{{ restaurant.logo }}" class="logo">
                    <ul>
                        <li *ngFor="let employee of restaurant.employees"
                            [routerLink]="['/r', restaurant.strid, employee.guid]">
                            <table>
                                <tr>
                                    <td class="photo">
                                        <img src="{{ employee.image }}" />
                                    </td>
                                    <td>
                                        <span *ngIf="tipsSent.includes(employee.guid)"><span class="badge rounded-pill bg-warning">TIP SENT TODAY</span><br></span>
                                        <span class="name">{{employee.firstName}}</span>
                                        <br>
                                        <span class="occupation">{{employee.occupation}}</span>
                                    </td>
                                    <td class="arrow">
                                        &#x203A;
                                    </td>
                                </tr>
                            </table>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>