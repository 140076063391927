import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  PaymentRequest,
  PaymentResponse,
  ResponseClientToken,
  Restaurant,
  RestaurantRequest,
  ReviewRequest,
  ReviewResponse,
} from '../interfaces/api.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private router: Router) {}

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(operation + ': ' + error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public loadRestaurant(
    request: RestaurantRequest,
    callback: (restaurant: Restaurant) => void
  ): void {
    this.http
      .post<Restaurant>(environment.apiUrl + '/api/restaurant', request)
      .subscribe(
        (response) => {
          if (response) {
            callback(response);
          } else {
            this.router.navigate(['/']);
            return;
          }
        },
        (err) => {
          this.router.navigate(['/']);
        }
      );
  }

  public validateMerchant(validationUrl: string): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/validateMerchant', {
      validationUrl,
    });
  }

  public tokenize(data: any): Observable<any> {
    return this.http.post<any>(environment.apiUrl + '/api/tokenize', {
      data,
    });
  }

  public submitReview(request: ReviewRequest): Observable<ReviewResponse> {
    return this.http.post<ReviewResponse>(
      environment.apiUrl + '/api/review',
      request
    );
  }

  public makePayment(
    paymentRequest: PaymentRequest,
    callback: (response: PaymentResponse | null) => void
  ): void {
    this.http
      .post<PaymentResponse>(
        environment.apiUrl + '/api/payment',
        paymentRequest
      )
      .subscribe(
        (response) => {
          callback(response);
        },
        () => callback(null)
      );
  }

  public loadClientToken() {
    return this.http.get<ResponseClientToken>(
      environment.apiUrl + '/api/client_token'
    );
  }
}
