<swiper *ngIf="initialValue"
    [slidesPerView]="5"
    [centeredSlides]="true"
    [freeModeSticky]="true"
    [watchSlidesProgress]="true"
    [watchSlidesVisibility]="true"
    [freeMode]="true"
    [initialSlide]="initialValue-1"
    (swiper)="onSwiper($event)"
    (slideChange)="onSlideChange()"
>

    <ng-container *ngFor='let x of sliderSequence()'>
        <ng-template swiperSlide>{{ x+1 }}</ng-template>
    </ng-container>
</swiper>

<div class="meter">
    <div class="svg-wrapper">
        <svg height="25" viewBox="0 0 360 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L1 5" stroke="#4800FF" stroke-opacity="0.2"  stroke-linecap="round"/>
            <path d="M20 1L20 5" stroke="#4800FF" stroke-opacity="0.4" stroke-linecap="round"/>
            <path d="M40 1L40 5" stroke="#4800FF" stroke-opacity="0.4" stroke-linecap="round"/>
            <path d="M60 1L60 5" stroke="#4800FF" stroke-opacity="0.5" stroke-linecap="round"/>
            <path d="M80 1L80 5" stroke="#4800FF" stroke-opacity="0.6" stroke-linecap="round"/>
            <path d="M100 1L100 5" stroke="#4800FF" stroke-opacity="0.7" stroke-linecap="round"/>
            <path d="M120 1L120 5" stroke="#4800FF" stroke-opacity="0.8" stroke-linecap="round"/>
            <path d="M140 1L140 5" stroke="#4800FF" stroke-opacity="0.9" stroke-linecap="round"/>
            <path d="M160 1L160 5" stroke="#4800FF" stroke-opacity="1" stroke-linecap="round"/>

            <path d="M179 1L179 25" stroke="#4800FF" stroke-linecap="round"/>
            <path d="M180 0L180 25" stroke="#4800FF" stroke-linecap="round"/>
            <path d="M181 1L181 25" stroke="#4800FF" stroke-linecap="round"/>

            <path d="M200 1L200 5" stroke="#4800FF" stroke-opacity="1" stroke-linecap="round"/>
            <path d="M220 1L220 5" stroke="#4800FF" stroke-opacity="0.9" stroke-linecap="round"/>
            <path d="M240 1L240 5" stroke="#4800FF" stroke-opacity="0.8" stroke-linecap="round"/>
            <path d="M260 1L260 5" stroke="#4800FF" stroke-opacity="0.7" stroke-linecap="round"/>
            <path d="M280 1L280 5" stroke="#4800FF" stroke-opacity="0.6" stroke-linecap="round"/>
            <path d="M300 1L300 5" stroke="#4800FF" stroke-opacity="0.5" stroke-linecap="round"/>
            <path d="M320 1L320 5" stroke="#4800FF" stroke-opacity="0.4" stroke-linecap="round"/>
            <path d="M340 1L340 5" stroke="#4800FF" stroke-opacity="0.3" stroke-linecap="round"/>
            <path d="M359 1L359 5" stroke="#4800FF" stroke-opacity="0.2" stroke-linecap="round"/></svg>
    </div>
    <div class="line"></div>
</div>