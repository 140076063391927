<div *ngIf="restaurant !== undefined" class="content">
    <div class="row">
        <div class="col">
            <tipeasy-header
                        [title]="restaurant.name"
                        [header]="'Tip Sent!'"
                        [checkMark]="true"
                        [spacer]="1"
            ></tipeasy-header>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="tipSent">
                <div class="total">
                    {{ tipAmount }}€<br>
                    <span class="paymentMethod">
                        {{ paymentType }}
                    </span>
                </div>
                <div class="arrow">
                    <svg width="77" height="19" viewBox="0 0 77 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.2" d="M1.5 10.5H72.9L66.1 17.3C65.7 17.7 65.7 18.3 66.1 18.7C66.3 18.9 66.6 19 66.8 19C67 19 67.3 18.9 67.5 18.7L76 10.2C76.4 9.8 76.4 9.2 76 8.8L67.5 0.3C67.1 -0.1 66.5 -0.1 66.1 0.3C65.7 0.7 65.7 1.3 66.1 1.7L72.9 8.5H1.5C0.9 8.5 0.5 8.9 0.5 9.5C0.5 10.1 0.9 10.5 1.5 10.5Z" fill="#180D34"/>
                    </svg>
                </div>
                <div class="employee">
                    {{ employee?.firstName }}
                </div>
            </div>
            <div class="rateService">
                Rate {{ employee?.firstName }} service<br>
                <span class="anonymousFeedback">(Your feedback is anonymous)</span>
            </div>


            <rating
                (selectedRating)="onSelectedRating($event)"
            ></rating>

            <div class="textarea">
                <textarea [(ngModel)]="reviewContent" placeholder="Leave a comment"></textarea>
            </div>

            <div class="d-grid gap-2 col-10 mx-auto pb-5">
                <button type="button" class="btn btn-lg btn-done" (click)="submitReview()">DONE</button>
            </div>
        </div>
    </div>
</div>