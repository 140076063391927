<main class="main" id="top">
  <nav class="navbar navbar-standard navbar-expand-lg fixed-top navbar-dark"
    data-navbar-darken-on-scroll="data-navbar-darken-on-scroll">
    <div class="container">
      <a class="navbar-brand" href="/">
        <span class="header-logo me-5"><img src="/assets/Logo_horizontal_white.svg" /></span></a>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard"
        aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
        <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
          <li class="nav-item">
            <a pageScroll class="nav-link link-white" href="#katasstrada">Kā tas strādā</a>
          </li>
          <li class="nav-item">
            <a pageScroll class="nav-link link-white" href="#uzstadisana">Uzstādīšana</a>
          </li>
          <li class="nav-item">
            <a pageScroll class="nav-link link-white" href="#kontakti">Kontakti</a>
          </li>
          <li class="nav-item">
            <a pageScroll class="nav-link link-white" href="#izmaksas">Izmaksas</a>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a class="
                btn btn-outline-light
                px-3
                py-1
                my-2
                me-2
                border-1
                fs--1
                rounded-pill
                nav-link
                link-white
              " href="https://dashboard.tipeasy.co" role="button">Ielogoties</a>
          </li>
          <li class="nav-item dropdown">
            <a class="
                btn btn-primary
                border-1
                fs--1
                px-3
                my-2
                py-1
                text-white
                rounded-pill
                ml-2
                nav-link
              " href="https://dashboard.tipeasy.co/register" role="button">Reģistrēties</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="py-0 light" id="banner">
    <div class="bg-holder overlay" style="
        background-image: url(../assets/img/landing_bg1.jpg);
        background-position: center bottom;
      "></div>
    <!--/.bg-holder-->
    <div class="container">
      <div class="row flex-center pt-8 pt-lg-10 pb-xl-0">
        <div class="
            col-md-5
            offset-md-2 offset-lg-0
            col-lg-4 col-xl-5
            pb-2 pb-xl-9
          ">
          <div class="pb-3 text-md-start text-center">
            <span class="
                border border-light
                mb-4
                fs--1
                fw-bold
                border-2
                rounded-pill
                px-3
                py-1
                text-white text-nowrap
              ">
              Mūsdienīga pieeja dzeramnaudām
            </span>
          </div>
          <h1 class="text-white text-md-start text-center">
            Dzeramnauda ar telefona kameru
          </h1>
          <ul class="lead text-white list-group-vertical-md ul-dash text-center text-md-start my-2 my-md-5 large fw-bold">
            <li>Ātri un viegli</li>
            <li>Bez skaidras naudas</li>
            <li>Droši</li>
            <li>Bez aplikācijas</li>
            <li>Novērtē servisu</li>
          </ul>
        </div>
        <div class="
            col-8 col-xl-5 col-xxl-4
            offset-lg-1
            col-lg-5 col-md-5
            offset-xl-1
            align-self-end
            text-center
          ">
          <div class="landing-banner-wrapper">
            <span class="landing-banner"><img src="../assets/img/landing/mob_lg.png" /></span>
          </div>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="py-3 bg-white position-relative">
    <div class="container">
      <div class="row flex-center mt-8 mb-4 mt-lg-7 mb-lg-7">
        <div class="
            col-12
            mt-6 mt-md-0
            col-md-6 col-lg-6 col-xl-6 col-xxl-5
            ps-lg-6
          ">
          <img class="img-fluid px-6 px-md-0" src="../assets/img/landing/nozares.png" alt="" />
        </div>
        <div class="
            col-md-4 col-lg-5
            offset-md-1
            col-xl-4
            mt-4
            text-md-start text-center
            pt-5
          ">
          <h2>Jebkurai nozarei</h2>
          <p class="xlarge mt-2 mt-md-5">
            #barber #barista #hotel #tattoo #waiter #fitness #makeupartist #taxi
            #bartender #delivery #hairdresser #nailspecialist<br />un daudzām
            citām...
          </p>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="bg-light text-center" id="katasstrada">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="">Kā tas strādā?</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="h-100">
            <div class="h-100">
              <div class="card-body pt-6 pb-4">
                <img src="../../assets/img/landing/mob_1.png" />
                <h2 class="fst-italic my-4">Noskenē</h2>
                <p class="small">Noskenē QR kodu ar savu telefona kameru</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="h-100">
            <div class="card-body pt-6 pb-4">
              <img src="../../assets/img/landing/mob_2.png" />
              <h2 class="fst-italic my-4">Izvēlies</h2>
              <p class="small">Izvēlies dzeramnaudas summu</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="h-100">
            <div class="card-body pt-6 pb-4">
              <img src="../../assets/img/landing/mob_3.png" />
              <h2 class="fst-italic my-4">Samaksā</h2>
              <p class="small">Maksā ar Apple Pay, Google Pay vai bankas karti</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="bg-white text-center">
    <div class="container">
      <div class="row flex-center mt-0 mt-md-3 mb-4 mt-lg-3 mb-lg-7">
        <div class="col-md-5 col-lg-4 col-xl-3 mt-0 mt-md-4 text-start">
          <h1 class="pb-md-4 text-md-start text-center">Pilna kontrole</h1>
          <ul class="text-center text-md-start ul-dash text-center text-md-start my-4 my-md-5 large">
            <li>Biznesa un darbinieku profili</li>
            <li>Naudas plūsmas atskaites</li>
            <li>Klientu atsauksmes</li>
          </ul>
        </div>
        <div class="
            col-12
            col-md-7 col-lg-8
            ps-lg-6
          ">
          <img class="img-fluid px-3 px-md-6 px-md-0" src="../assets/img/landing/computers.png" alt="" />
        </div>
      </div>
      <div class="row mt-6">
        <div class="col-lg-3">
          <div class="h-100">
            <div class="blue-icn">
              <img src="../../assets/img/landing/icn_report.svg" />
            </div>
            <h4 class="fst-italic my-4">Atskaites</h4>
            <p class="small mx-xl-5">Detalizētas naudas plūsmas atskaites</p>
          </div>
        </div>
        <div class="col-lg-3 mt-6 mt-lg-0">
          <div class="blue-icn">
            <img src="../../assets/img/landing/icn_admin.svg" />
          </div>
          <h4 class="fst-italic my-4">Administrēšana</h4>
          <p class="small mx-xl-5">Biznesu un darbinieku profilu administrēšana</p>
        </div>
        <div class="col-lg-3 mt-6 mt-lg-0">
          <div class="blue-icn">
            <img src="../../assets/img/landing/icn_leavereview.svg" />
          </div>
          <h4 class="fst-italic my-4">Atsauksmes</h4>
          <p class="small mx-xl-5">Atsauksmes par darbiniekiem un servisu</p>
        </div>
        <div class="col-lg-3 mt-6 mt-lg-0">
          <div class="blue-icn">
            <img src="../../assets/img/landing/icn_qr.svg" />
          </div>
          <h4 class="fst-italic my-4">QR kodi</h4>
          <p class="small mx-xl-5">Ģenerē biznesa un darbinieku QR kodus</p>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="bg-blue text-center" id="uzstadisana">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="text-white">Sāc lietot jau tagad</h1>
        </div>
      </div>
      <div class="row mt-6">
        <div class="col-lg-3 align-self-start text-center">
          <div class="h-fix-150">
            <img class="h-fix-img" src="../../assets/img/landing/icn_regbusiness.svg" />
          </div>
          <h2 class="fst-italic text-white mt-4 fs-2 fs-lg-3">Reģistrē savu biznesu</h2>
        </div>
        <div class="d-none d-lg-block col-lg-1 h-fix-150">
          <img class="mt-5" src="../../assets/img/landing/arrow_right.svg" />
        </div>
        <div class="col-lg-3 mt-6 mt-lg-0 align-self-start">
          <div class="h-fix-150">
            <img class="h-fix-img"src="../../assets/img/landing/icn_addemployee.svg" />
          </div>
          <h2 class="fst-italic text-white mt-4 fs-2 fs-lg-3">Pievieno darbiniekus</h2>
        </div>
        <div class="d-none d-lg-block col-lg-1 h-fix-150">
          <img class="mt-5" src="../../assets/img/landing/arrow_right.svg" />
        </div>
        <div class="col-lg-3 mt-6 mt-lg-0 align-self-start">
          <div class="h-fix-150 ">
            <img class="h-fix-img" src="../../assets/img/landing/icn_earnings.svg" />
          </div>
          <h2 class="fst-italic text-white mt-4 fs-2 fs-lg-3">
            Lieto QR kodus un saņem dzeramnaudu!
          </h2>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="bg-white text-center">
    <div class="container">
      <div class="row">
        <div class="col">
          <h1 class="">Mūsu klienti</h1>
        </div>
      </div>
      <div class="row mt-6">
        <div class="col-12 col-lg-4">
          <div class="border border-1 border-dark p-4">
            <div class="row h-fix-150">
              <div class="col-12 align-self-center">
                <img class="img-responsive" src="../../assets/img/landing/partner_masti.png" />
              </div>
            </div>
          </div>
          <div class="text-start mt-4 mb-6 mb-lg-0">
            <h3 class="fst-italic">5 masti</h3>
            <p class="small">Ozolnieki LV-3018</p>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="border border-1 border-dark p-4">
            <div class="row h-fix-150">
              <div class="col-12 align-self-center">
                <img class="img-responsive" src="../../assets/img/landing/partner_sinteze.png" />
              </div>
            </div>
          </div>
          <div class="text-start mt-4 mb-6 mb-lg-0">
            <h3 class="fst-italic">Restorāns Sintēze</h3>
            <p class="small">Roberta Feldmaņa iela 11, Rīga, LV-1014</p>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="border border-1 border-dark p-4">
            <div class="row h-fix-150">
              <div class="col-12 align-self-center">
                <img class="img-responsive" src="../../assets/img/landing/partner_rest.png" />
              </div>
            </div>
          </div>
          <div class="text-start mt-4 mb-6 mb-lg-0">
            <h3 class="fst-italic">Rest Garšotava</h3>
            <p class="small">Liepājas iela 56, Rīga</p>
          </div>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="bg-orange text-center" id="izmaksas">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 align-self-start text-center text-md-start">
          <h1 class="text-white">Kādas ir izmaksas?</h1>
        </div>
        <div class="col-lg-7 mt-4 mt-lg-2 mt-xl-3 text-center text-md-start align-self-start">
          <h5 class="text-white fs-1 fs-lg-3 fs-xl-4">
            Tipeasy.co komisijas maksa ir līdz 10% no darījuma summas*
          </h5>
          <small>* Neiekļaujot nodokļus un citas izmaksas</small>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
    <!--
  <section class="bg-light text-start">
    <div class="container">
      <div class="row mt-6">
        <div class="col-12">
          <h1>Biežāk uzdotie jautājumi</h1>

          <div class="accordion" id="faq">
            <div class="card">
              <div class="card-header">
                <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1"
                  aria-expanded="true" aria-controls="collapse1">
                  Kā noskanēt QR kodu?
                </button>
              </div>

              <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#faq">
                <div class="card-body">
                  Ieslēdz telefona fotokameru un pavērs to pret QR kodu. Uzspied
                  uz linku, kas parādās uz ekrāna paziņojumā.
                </div>
              </div>
            </div>

            <div class="card mt-2">
              <div class="card-header">
                <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2"
                  aria-expanded="true" aria-controls="collapse2">
                  Kur nonāk nauda maksājot ar Tipeasy.co?
                </button>
              </div>

              <div id="collapse2" class="collapse" aria-labelledby="heading2" data-parent="#faq">
                <div class="card-body">
                  No sākuma nauda nonāk pie mums, tad mēs to pārskaitam uz
                  uzņēmuma kontu, kopā ar detalizētām atskaitēm par katra
                  darbinieka nopelnīto.
                </div>
              </div>
            </div>

            <div class="card mt-2">
              <div class="card-header">
                <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3"
                  aria-expanded="true" aria-controls="collapse3">
                  Kā varu sākt lietot Tipeasy.co savā biznesā?
                </button>
              </div>

              <div id="collapse3" class="collapse" aria-labelledby="heading3" data-parent="#faq">
                <div class="card-body">
                  <a href="https://dashboard.tipeasy.co/register">Reģistrē</a>
                  kontu mūsu sistēmā un mēs ar tevi sazināsimies par tālākām
                  darbībām.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>-->
  <!-- <section> close ============================-->
  <!-- ============================================-->

  <!-- ============================================-->
  <!-- <section> begin ============================-->
  <section class="bg-dark light" id="kontakti">
    <div>
      <hr class="my-0 opacity-25" />
      <div class="container py-3">
        <div class="row justify-content-between">
          <div class="col-12 col-sm-auto text-start text-md-center">
            <h1 class="text-white mb-3 mb-sm-0">Kontakti</h1>
          </div>
          <div class="col-12 col-sm-auto text-center text-white">
            <table>
              <tr>
                <td class="align-middle">
                  <img class="me-3" width="33" src="../../assets/img/landing/phone.svg" />
                </td>
                <td class="align-middle">
                  <p class="text-white m-0 fw-bold fs-2 fs-md-4">+371 29 462 908</p>
                </td>
              </tr>
              <tr>
                <td colspan="2"><div class="m-2"></div></td>
              </tr>
              <tr>
                <td class="align-middle">
                  <img class="me-3" width="33" src="../../assets/img/landing/mail.svg" />
                </td>
                <td class="align-middle">
                  <p class="text-white m-0 fw-bold fs-2 fs-md-4">help@tipeasy.co</p>
                </td>
              </tr>
              <tr>
                <td colspan="2"><div class="m-2"></div></td>
              </tr>
              <tr>
                <td colspan="2" class="text-start text-sm-end">
                  <a class="me-3" href=""><img src="../../assets/img/landing/facebook.svg" /></a>
                  <a href=""><img src="../../assets/img/landing/instagram.svg" /></a>
                </td>
              </tr>
            </table>
          </div>
          <div class="row justify-content-between fs--1">
            <div class="col">Tipeasy &copy; 2021</div>
          </div>
        </div>
      </div>
    </div>
    <!-- end of .container-->
  </section>
  <!-- <section> close ============================-->
  <!-- ============================================-->
</main>
<!-- ===============================================-->
<!--    End of Main Content-->
<!-- ===============================================-->