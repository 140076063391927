<div [hidden]="restaurant !== undefined && !paymentInProgress" class="loader"></div>
<div [hidden]="restaurant === undefined || paymentInProgress">
    <div *ngIf="restaurant !== undefined" class="content">
        <div class="row">
            <div class="col">
                <div class="col">
                    <tipeasy-header
                        [backLink]="['/r', restaurant.strid]"
                        [header]="'Send tip to ' + employee?.firstName"
                        [photo]="employee?.image"
                        [spacer]="3"
                    ></tipeasy-header>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="tipSelect">

                    <radio-toggle
                        [selectedOption]="radioToggle"
                        [leftOption]="'Choose amount'"
                        [rightOption]="'Enter amount'"
                        (onSelect)="setRadioToggle($event)"
                    ></radio-toggle>

                    <money-slider *ngIf="radioToggle=='left' && selectedAmount"
                        [initialValue]="selectedAmount"
                        (onSelectValue)="selectAmount($event)"
                    ></money-slider>

                    <div class="input-amount" *ngIf="radioToggle=='right'">
                        <div class="wrapper-eur">
                            <input id="inputAmount" type="number" #inputAmount (keyup)="selectAmount(inputAmount.value, true)"
                                [value]="selectedAmount"
                                placeholder="Enter custom tip amount">
                        </div>
                    </div>
                </div>

                <span class="middle-text v-spacing">Choose how to send the tip</span>

                <div id="payment-buttons" class="payment-buttons payment-buttons-black">
                    <a [routerLink]="" id="apple-pay-button" class="disabled"><span></span></a>
                    <a [routerLink]="" id="google-pay-button" class="disabled"><span></span></a>
                    <!--<div id="payment-request-button"></div>-->
                </div>

                <div class="payment-buttons payment-buttons-transparent">
                    <a [routerLink]="" id="card-pay-button" class="disabled"><i class="fas fa-credit-card"></i>
                        Pay with bank card</a>
                </div>
            </div>
        </div>
    </div>
</div>