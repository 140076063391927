export enum Currency {
  EUR = 'EUR',
  USD = 'USD',
}
export interface Message {
  message: string;
}

export interface Employee {
  firstName: string;
  occupation: string;
  image: string;
  guid: string;
}
export interface Restaurant {
  name: string;
  strid: string;
  background: string;
  logo: string;
  tipAmounts: number[];
  tipCurrency: Currency;
  tipCurrencySymbol: string;
  employees: Employee[];
}

export interface RestaurantRequest {
  strid?: string;
  employeeGuid?: string;
}

export interface ResponseClientToken {
  result: string;
  token: string;
}
export interface PaymentRequest {
  gateway: 'stripe' | 'braintree';
  source: 'android_pay_card' | 'apple_pay_card' | 'credit_card';
  nonce?: string;
  appleToken?: any;
  amount: string;
  currency: Currency;
  employeeGuid: string;
  deviceData?: string;
}

export interface PaymentResponse {
  result: 'OK' | 'FAILED';
  paymentId?: string;
  message?: string;
}

export interface ReviewRequest {
  review: string;
  stars: number;
  paymentId: string;
}
export interface ReviewResponse {
  result: 'OK' | 'FAILED';
}

export interface ValidateMerchantResponse {
  merchantSession: string;
}

export interface TipSent {
  guid: string;
  ts: Date;
}
