<header>
    <div class="top">
        <div class="left">
            <a *ngIf="backLink" [routerLink]="backLink" class="line-10 arrow white">&#x2039;</a>
        </div>

        <div class="title">
            {{ title }}
        </div>

        <div class="right">
            <!--<div class="dropdown">
                <span class="dropdown-toggle" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">EN</span>
                <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="#">Latviski</a>
                    <a class="dropdown-item" href="#">English</a>
                    <a class="dropdown-item" href="#">По-русски</a>
                </div>
            </div>-->

            <div>
                <a (click)="showInfo()"><i class="fas fa-info-circle fa-2x"></i></a>
            </div>
        </div>
    </div>

    <div *ngIf="photo" class="photo">
        <div *ngIf="photoCheck" class="check">
            <span class="fa-stack fa-1x">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fas fa-check-circle fa-stack-2x fa-inverse"></i>
            </span>
        </div>
        <img src="{{ photo }}" />
    </div>

    <div *ngIf="checkMark" class="checkmark">
        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 426.666 426.666">
            <path d="M213.333 0C95.518 0 0 95.514 0 213.333s95.518 213.333 213.333 213.333c117.828 0 213.333-95.514 213.333-213.333S331.156 0 213.333 0zM174.2 322.918l-93.936-93.93 31.31-31.31 62.625 62.622 140.893-140.898 31.31 31.31-172.204 172.206z"
            fill="#E29800"/>
        </svg>
    </div>

    <h1>{{ header }}</h1>
    <div *ngIf="headerSub" class="headerSub">{{ headerSub }}</div>

    <div *ngIf="spacer">
        <ng-container *ngFor='let x of spacerSequence()'>
            <div class="pb-5"></div>
        </ng-container>
    </div>
</header>