<div class="infoBox">
    <div class="client-wrapper p-4">

        <div class="text-end p-2" (click)="close()">
            <i class="fas fa-times fa-2x"></i>
        </div>

        <div class="text-center p-5">
            <img src="/assets/TipEasy_logo_Dark_horizontal.svg">
        </div>

        <p>
            Tipeasy piedāvā ērtu un drošu bezkontakta risinājumu dzeramnaudas atstāšanai.
        </p>

        <p>
            Vienkārši noskanējiet QR kodu, sekojiet norādēm un par pārējo parūpēsimies mēs!
        </p>

    </div>
</div>
