import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Restaurant, Employee, ReviewRequest } from '../../interfaces/api.interface';
import { ApiService } from '../../_services/api.service';
import { SessionService } from '../../_services/session.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
  public restaurant: Restaurant | undefined;
  public employee: Employee | undefined;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    public sessionService: SessionService
  ) {}

  ngOnInit(): void {
    const mainWrapper = document.querySelector('.client-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.remove('whitebg');
    }

    this.route.paramMap.subscribe((params) => {
      this.restaurant = this.sessionService.getRestaurant();
      this.employee = this.sessionService.getEmployee();

      if(!this.restaurant || !this.employee) {
        this.router.navigate(['/']);
        return;
      }
    });
  }

}
