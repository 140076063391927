import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'radio-toggle',
  templateUrl: './radio-slider.component.html',
  styleUrls: ['./radio-slider.component.scss'],
})
export class RadioSliderComponent implements OnInit {
  @Input()
  leftOption: string = '';

  @Input()
  rightOption: string = '';

  @Output()
  onSelect: EventEmitter<'left' | 'right'> = new EventEmitter();

  @Input()
  selectedOption: 'left' | 'right' = 'left';

  constructor() {}

  ngOnInit(): void {}

  selectItem(item: 'left' | 'right') {
    this.selectedOption = item;
    this.onSelect.emit(item);
  }
}
