import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  Employee,
  Restaurant,
  TipSent,
} from '../../../interfaces/api.interface';
import { SessionService } from '../../../_services/session.service';

@Component({
  selector: 'tipeasy-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  public restaurant: Restaurant | undefined;
  public employee: Employee | undefined;

  constructor(private router: Router, public sessionService: SessionService) {}

  arrayUnique<T>(a: T[]): T[] {
    return a.filter((item, pos) => a.indexOf(item) === pos);
  }

  isToday(someDate: Date) {
    const today = new Date();
    someDate = new Date(someDate);
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  ngOnInit(): void {
    const mainWrapper = document.querySelector('.client-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.add('whitebg');
    }

    this.restaurant = this.sessionService.getRestaurant();
    this.employee = this.sessionService.getEmployee();

    if (!this.restaurant || !this.employee) {
      this.router.navigate(['/']);
      return;
    }

    let tipsSent: TipSent[] = [];
    try {
      tipsSent = JSON.parse(localStorage.getItem('tipsSentObj') ?? '');
    } catch (e) {}

    // remove expired items
    tipsSent = tipsSent.filter((n) => this.isToday(n.ts));
    tipsSent = tipsSent.slice(0, 99); // limit to 100 records at most

    if (!tipsSent.find((n) => n.guid === this.employee?.guid)) {
      tipsSent.push({
        guid: this.employee.guid,
        ts: new Date(),
      });
      localStorage.setItem('tipsSentObj', JSON.stringify(tipsSent));
    }
  }
}
